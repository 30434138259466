<template>
    <b-container fluid>
        <b-row>
            <b-col sm="12">
              <iq-card>
                <template v-slot:headerTitle>
                    <div class="text-center">
                        <h5 class="card-title"><i class="ri-edit-line"></i> {{ $t('externalIncentive.subsidy_application_form') }}</h5>
                    </div>
                  </template>
                  <template v-slot:body>
                    <profile-status-msg :preConditionLoader="preConditionLoader" :profileStatus="profileStatus" :profile-url="'/incentive-farmer/profile'" :inForm="true"/>
                      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                          <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                            <b-overlay :show="loading">
                                <div @click="displayProfileCheckAlert">
                                    <b-row class="mb-2">
                                        <b-col sm="4">
                                            <ValidationProvider name="Circular" vid="circular_id">
                                                <b-form-group class="row" label-cols-sm="3" label-for="name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{ $t('dae_subsidy.circular') }}
                                                </template>
                                                <b-form-input
                                                disabled
                                                type="text"
                                                id="circularName"
                                                v-model="circularName"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">{{ $t('externalIncentive.farmer_gen_Info') }}</h5>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="3">
                                            <ValidationProvider name="Name (En)" vid="name" rules="required">
                                                <b-form-group class="row" label-cols-sm="12" label-for="name" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('common_config.farmer_name_en')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                disabled
                                                type="text"
                                                id="name"
                                                v-model="application.name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Name (Bn)" vid="name_bn" rules="required">
                                                <b-form-group class="row" label-cols-sm="12" label-for="name_bn" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('common_config.farmer_name_bn')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                disabled
                                                type="text"
                                                id="pay_grade"
                                                v-model="application.name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Father Name (En)" vid="father_name" rules="required">
                                                <b-form-group class="row" label-cols-sm="12" label-for="father_name" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{$t('common_config.farmer_father_name_en')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    disabled
                                                    type="text"
                                                    id="pay_grade"
                                                    v-model="application.father_name"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Father Name (Bn)" vid="father_name_bn" rules="required">
                                                <b-form-group class="row" label-cols-sm="12" label-for="father_name_bn" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{$t('common_config.farmer_father_name_bn')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    disabled
                                                    v-model="application.father_name_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="NID" vid="nid_no" rules="required">
                                                <b-form-group class="row" label-cols-sm="12" label-for="nid" slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                        {{$t('admission_form.nid_no')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        disabled
                                                        type="number"
                                                        id="nid_no"
                                                        v-model="application.nid_no"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                        <ValidationProvider name="Mobile No" vid="mobile_no" rules="required|digits:11">
                                            <b-form-group class="row" label-cols-sm="12" label-for="mobile_no" slot-scope="{ valid, errors }">
                                                <template v-slot:label>
                                                    {{$t('admission_form.mobile_no')}} <span class="text-danger">*</span>
                                                </template>
                                                <b-form-input
                                                    disabled
                                                    type="number"
                                                    id="mobile_no"
                                                    v-model="application.mobile_no"
                                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">{{ $t('externalTraining.present_address') }}</h5>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="3">
                                            <ValidationProvider name="Area Type" vid="area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.area_type_id"
                                                    id="area_type_id"
                                                    :options="areaTypeList"
                                                    @change="getAreaTypeData(application.area_type_id)"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShow">
                                            <ValidationProvider name="Division" vid="division_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.division_id"
                                                    :options="divisionList"
                                                    id="division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShow">
                                            <ValidationProvider name="District" vid="district_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.district_id"
                                                    :options="districtList"
                                                    id="district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="PauroshobaItemShow || UnionItemShow">
                                            <ValidationProvider name="Upazila" vid="upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.upazilla_id"
                                                    :options="upazilaList"
                                                    id="upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="UnionItemShow">
                                            <ValidationProvider name="Union" vid="union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.union_id"
                                                    :options="unionList"
                                                    id="union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="CityCorpItemShow">
                                            <ValidationProvider name="City Corporation" vid="city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.city_corporation_id"
                                                    :options="cityCorporationList"
                                                    id="city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="PauroshobaItemShow">
                                            <ValidationProvider name="Pauroshoba" vid="pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.pauroshoba_id"
                                                    :options="pauroshobaList"
                                                    id="pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShow && CityCorpItemShow">
                                            <ValidationProvider name="Ward" vid="ward_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    disabled
                                                    plain
                                                    v-model="application.ward_id"
                                                    :options="wardList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="4" sm="12" v-show="ItemShow">
                                            <ValidationProvider name="Address" vid="address_en">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="address_en"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                                    </template>
                                                    <b-form-input
                                                    type="text"
                                                    id="address_en"
                                                    v-model="application.address_en"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="4" sm="12" v-show="ItemShow">
                                            <ValidationProvider name="Address" vid="address_bn">
                                                <b-form-group
                                                    class="row"
                                                    label-for="address_bn"
                                                    label-cols-sm="12"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                    </template>
                                                    <b-form-input
                                                    type="text"
                                                    id="address_bn"
                                                    v-model="application.address_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">{{ $t('educational_management.permanent_address') }}</h5>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6">
                                            <ValidationProvider>
                                                <b-form-group
                                                    class="row mb-0"
                                                    :label="$t('educational_management.present_business_address')"
                                                    label-cols-sm="3"
                                                    label-for="present_address"
                                                    slot-scope="{ errors }"
                                                >
                                                    <div>
                                                        <b-form-checkbox
                                                            class="mt-1"
                                                            id="same_as_present_address"
                                                            v-model="same_as_present_address"
                                                            :value="true"
                                                            :unchecked-value="false"
                                                        >
                                                            {{$t('admission_form.same')}}
                                                        </b-form-checkbox>
                                                    </div>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="3">
                                            <ValidationProvider name="Area Type" vid="per_area_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_area_type_id "
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                        {{$t('org_pro.area_type')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        :disabled="same_as_present_address"
                                                        v-model="application.per_area_type_id"
                                                        id="per_area_type_id"
                                                        :options="areaTypeList"
                                                        @change="getAreaTypePerData(application.per_area_type_id)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShowPer">
                                            <ValidationProvider name="Division" vid="per_division_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_division.division')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_division_id"
                                                    :options="divisionPerList"
                                                    id="per_division_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShowPer">
                                            <ValidationProvider name="District" vid="per_district_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_district.district')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_district_id"
                                                    :options="districtPerList"
                                                    id="per_district_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="PauroshobaItemPerShow || UnionItemPerShow">
                                            <ValidationProvider name="Upazila" vid="per_upazila_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_upazila_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_upazilla_id"
                                                    :options="upazilaPerList"
                                                    id="per_upazila_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="UnionItemPerShow">
                                            <ValidationProvider name="Union" vid="per_union_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_union_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('org_pro_union.union')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_union_id"
                                                    :options="unionPerList"
                                                    id="per_union_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="CityCorpItemPerShow">
                                            <ValidationProvider name="City Corporation" vid="per_city_corporation_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_city_corporation_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.city_corporation') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_city_corporation_id"
                                                    :options="cityCorporationPerList"
                                                    id="per_city_corporation_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="PauroshobaItemPerShow">
                                            <ValidationProvider name="Pauroshoba" vid="per_pauroshoba_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_pauroshoba_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.pauroshoba') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_pauroshoba_id"
                                                    :options="pauroshobaPerList"
                                                    id="per_pauroshoba_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3" v-show="ItemShowPer && CityCorpItemPerShow">
                                            <ValidationProvider name="Ward" vid="per_ward_id">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_ward_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('org_pro.ward') }}
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    :disabled="same_as_present_address"
                                                    v-model="application.per_ward_id"
                                                    :options="wardPerList"
                                                    id="ward_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="4" sm="12" v-show="ItemShowPer">
                                            <ValidationProvider name="address" vid="per_address_en">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="per_address_en"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.enn') }}
                                                    </template>
                                                    <b-form-input
                                                        type="text"
                                                        id="per_address_en"
                                                        :disabled="same_as_present_address === 1"
                                                        v-model="application.per_address_en"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col lg="4" sm="12" v-show="ItemShowPer">
                                        <ValidationProvider name="Address" vid="per_address_bn">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="per_address_bn"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('farm_config.address') }} {{ ' ' + $t('globalTrans.bnn') }}
                                                </template>
                                                <b-form-input
                                                    type="text"
                                                    id="per_block_bn"
                                                    :disabled="same_as_present_address === 1"
                                                    v-model="application.per_address_bn"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col>
                                            <h5 class="ex_form_title">{{ $t('externalIncentive.machineries_info') }}</h5>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-2">
                                        <b-col sm="3">
                                            <ValidationProvider name="Machine Type" vid="machine_type_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="machine_type_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                     {{ $t('farm_config.machine_type') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="application.machine_type_id"
                                                    :options="machineTypeList"
                                                    id="machine_type_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Instrument" vid="instrument_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="instrument_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('dae_subsidy.instrument_name') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="application.instrument_id"
                                                    :options="instrumentList"
                                                    id="instrument_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Brand" vid="brand_id" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="brand_id"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('farm_config.brand') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="application.brand_id"
                                                    :options="brandList"
                                                    id="brand_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="Model" vid='model' rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="model"
                                                    slot-scope="{ valid, errors }"
                                                >
                                                    <template v-slot:label>
                                                    {{ $t('dae_subsidy.model')}}<span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                    id="model"
                                                    v-model="application.model"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-input>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col sm="3">
                                            <ValidationProvider name="application Type" vid="application_type" rules="required|min_value:1">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="12"
                                                    label-for="application_type"
                                                    slot-scope="{ valid, errors }">
                                                    <template v-slot:label>
                                                    {{ $t('dae_subsidy.applicationType') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                    plain
                                                    v-model="application.application_type"
                                                    :options="applicationTypeList"
                                                    id="application_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                    </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                    </b-row>
                                    <div v-if="application.application_type === 2" class="mb-3">
                                        <template>
                                            <b-row>
                                                <b-col>
                                                    <h5 class="ex_form_title">{{ $t('externalIncentive.group_indi_info') }}</h5>
                                                </b-col>
                                            </b-row>
                                        </template>
                                        <template>
                                            <b-row v-for="(detail,index) in application.details" :key="index">
                                                <b-col sm="2">
                                                    <ValidationProvider name="Farmer Name (En)" :vid="`farmer_name${index}`" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            :label-for="`farmer_name${index}`"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_subsidy.farmer_name_en')}} <span class="text-danger">*</span>
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_name"
                                                            v-model="detail.farmer_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="2">
                                                    <ValidationProvider name="Farmer Name (Bn)" :vid="`farmer_name_bn${index}`" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            :label-for="`farmer_name_bn${index}`"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_subsidy.farmer_name_bn')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        id="farmer_name_bn"
                                                        v-model="detail.farmer_name_bn"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="2">
                                                    <ValidationProvider name="Father Name (En)" :vid="`father_name${index}`" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            :label-for="`father_name${index}`"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('dae_subsidy.father_name_en')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="father_name"
                                                            v-model="detail.father_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="2">
                                                    <ValidationProvider name="Father Name (Bn)" :vid="`father_name_bn${index}`" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            :label-for="`father_name_bn${index}`"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('dae_subsidy.father_name_bn')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                            id="father_name_bn"
                                                            v-model="detail.father_name_bn"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="2">
                                                    <ValidationProvider name="Mobile Number" :vid="`mobile_no${index}`" rules="digits:11|required">
                                                        <b-form-group
                                                        class="row"
                                                        type="number"
                                                        label-cols-sm="12"
                                                        :label-for="`mobile_no${index}`"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_subsidy.mobile_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        type="number"
                                                        id="mobile_no"
                                                        v-model="detail.mobile_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col sm="2">
                                                    <ValidationProvider name="NID" :vid="`nid${index}`" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        :label-for="`nid${index}`"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('dae_subsidy.nid')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        type="number"
                                                        id="nid"
                                                        v-model="detail.nid"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <div class="text-right">
                                                <b-button variant="success" size="sm" class="mr-1" @click="add()"><i class="ri-add-line m-0"></i>{{$t('globalTrans.add_more')}}</b-button>
                                                <b-button v-show="index || ( !index && application.details.length > 1)" variant="danger" size="sm" @click="remove(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="text-right">
                                        <a href="javascript:" @click="saveMessage()" class="btn btn-success mr-2">{{$t('globalTrans.dakhil') }}</a>
                                        <router-link :to="{ path: '/incentive-farmer/subsidy-application'}" class="btn btn-danger">
                                            {{ $t('globalTrans.cancel') }}
                                        </router-link>
                                    </div>
                                </div>
                            </b-overlay>
                          </b-form>
                      </ValidationObserver>
                  </template>
              </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ProfileStatusMsg from '@/modules/external-user-service/irrigation/components/ProfileStatusMsg'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { applicationStore, applicationUpdate, profileInfoShow, circInstrList, applicationShow } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider,
      ProfileStatusMsg
    },
    data () {
        return {
          loading: false,
          profileStatus: 0,
          preConditionLoader: false,
          showIdType: 0,
          ItemShow: false,
          inputFieldShow: false,
          ItemShowPer: false,
          CityCorpItemShow: false,
          CityCorpItemPerShow: false,
          PauroshobaItemShow: false,
          PauroshobaItemPerShow: false,
          UnionItemShow: false,
          UnionItemPerShow: false,
          same_as_present_address: false,
          districtList: [],
          upazilaList: [],
          unionList: [],
          cityCorporationList: [],
          pauroshobaList: [],
          wardList: [],
          blockList: [],
          blockPerList: [],
          districtPerList: [],
          upazilaPerList: [],
          unionPerList: [],
          cityCorporationPerList: [],
          pauroshobaPerList: [],
          instrumentList: [],
          instrumentListData: [],
          application: {
            circular_id: 0,
            mac_farmer_id: 0,
            machine_type_id: 0,
            instrument_id: 0,
            brand_id: 0,
            application_type: 0,
            model: '',
            details: [
              {
                farmer_name: '',
                farmer_name_bn: '',
                father_name: '',
                father_name_bn: '',
                mobile_no: '',
                nid: ''
              }
            ]
          },
          genderList: [
            { value: 1, text: this.$t('common_config.male') },
            { value: 2, text: this.$t('common_config.female') },
            { value: 3, text: this.$t('common_config.other') }
          ],
          wardPerList: [],
          brandList: [],
          applicationStore: applicationStore,
          circularName: '',
          machineTypeList: []
        }
    },
    created () {
      let appCircularId = 0
      const circularId = parseInt(this.$route.query.circularId)
      const portalCircularId = parseInt(this.$store.state.Portal.serviceUrl.subsidyCircularId)
      if (circularId) {
        appCircularId = circularId
        this.application = Object.assign(this.application, { circular_id: circularId })
        const cir = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.circularList.find(cir => cir.value === circularId)
        this.circularName = this.$i18n.locale === 'bn' ? cir?.text_bn : cir?.text_en
      } else if (portalCircularId !== 0) {
        appCircularId = portalCircularId
        this.application = Object.assign(this.application, { circular_id: portalCircularId })
        const cir = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.circularList.find(cir => cir.value === portalCircularId)
        this.circularName = this.$i18n.locale === 'bn' ? cir?.text_bn : cir?.text_en
      } else {
        this.$swal({
          title: this.$t('externalIncentive.circularNotFound')
        })
        this.$router.push('/incentive-farmer/circular-list')
      }
      if (this.$route.query.id) {
        this.loading = true
        this.getApplicationData(this.$route.query.id)
      } else {
        this.farmerGeneralInfo(appCircularId)
      }
    },
    mounted () {
      flatpickr('.date-picker', {})
      core.index()
    },
    watch: {
        'application.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'application.per_division_id': function (newVal, oldVal) {
            this.districtPerList = this.getDistrictPerList(newVal)
        },
        'application.district_id': function (newVal, oldVal) {
            this.cityCorporationList = this.getCityCorporationList(newVal)
            this.upazilaList = this.getUpazilaList(newVal)
        },
        'application.per_district_id': function (newVal, oldVal) {
            this.cityCorporationPerList = this.getCityCorporationPerList(newVal)
            this.upazilaPerList = this.getUpazilaPerList(newVal)
        },
        'application.upazilla_id': function (newVal, oldVal) {
            this.unionList = this.getUnionList(newVal)
            this.pauroshobaList = this.getPauroshobaList(newVal)
        },
        'application.per_upazilla_id': function (newVal, oldVal) {
            this.unionPerList = this.getUnionPerList(newVal)
            this.pauroshobaPerList = this.getPauroshobaPerList(newVal)
        },
        'application.city_corporation_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByCityCorportaion(newVal)
        },
        'application.per_city_corporation_id': function (newVal, oldVal) {
            this.wardPerList = this.getWardListByCityCorportaionPer(newVal)
        },
        'application.union_id': function (newVal, oldVal) {
            this.wardList = this.getWardListByUnion(newVal)
        },
        'application.per_union_id': function (newVal, oldVal) {
            this.wardPerList = this.getWardPerListByUnion(newVal)
        },
        'application.machine_type_id': function (newVal, oldVal) {
            if (newVal !== 0) {
                this.instrumentList = this.instrumentListData.filter(item => item.machine_type_id === newVal)
            }
        },
        'application.instrument_id': function (newVal, oldVal) {
            if (newVal !== 0) {
                this.brandList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macBrandList.filter(item => item.instrument_name_id === newVal)
            }
        },
        'application.circular_id': function (newVal, oldVal) {
          if (newVal !== oldVal && newVal > 0) {
            this.getInstrumentList(newVal)
          }
        },
        same_as_present_address: function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.application.per_area_type_id = this.application.area_type_id
                this.application.per_city_corporation_id = this.application.city_corporation_id
                this.application.per_pauroshoba_id = this.application.pauroshoba_id
                this.application.per_division_id = this.application.division_id
                this.application.per_district_id = this.application.district_id
                this.application.per_upazilla_id = this.application.upazilla_id
                this.application.per_union_id = this.application.union_id
                this.application.per_ward_id = this.application.ward_id

                this.application.per_village_en = this.application.village_en
                this.application.per_village_bn = this.application.village_bn
                this.application.per_block_en = this.application.block_en
                this.application.per_block_bn = this.application.block_bn

                this.ItemShowPer = true
                if (this.application.per_area_type_id === 1) {
                    this.CityCorpItemPerShow = true
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 2) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = true
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 3) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = true
                }
            }
        }
    },
    computed: {
        authUser () {
            return this.$store.state.Auth.authUser
        },
        // machineTypeList: function () {
        //     return this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macMachineTypeList.filter(item => item.status === 1)
        // },
        supplierList: function () {
            const dataList = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.supplierList.filter(item => item.status === 1)
            return dataList.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        landTypeList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Haor' : 'হাওর' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Non-haor' : 'সমতল' }
            ]
            return list
        },
        applicationTypeList: function () {
            const dataList = [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Individual' : 'ব্যক্তিগত' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Group' : 'গ্রুপ' }
            ]
            return dataList
        },
        divisionList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        },
        divisionPerList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.divisionList.filter(item => item.status === 0)
        },
        cropInfoList: function () {
            return this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.cropList.filter(item => item.status === 1)
        },
        areaTypeList: function () {
            const list = [
                { value: 1, text: this.$i18n.locale === 'bn' ? 'সিটি কর্পোরেশন' : 'City Corpoation' },
                { value: 2, text: this.$i18n.locale === 'bn' ? 'পৌরসভা' : 'Pauroshoba' },
                { value: 3, text: this.$i18n.locale === 'bn' ? 'ইউনিয়ন' : 'Union' }
            ]
            return list
        },
        circularList: function () {
            const today = new Date().toISOString().slice(0, 10)
            const circulars = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.circularList.filter(item => item.status === 1 && item.type === 1 && item.application_deadline >= today)
            return circulars.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        }
    },
    methods: {
        getAreaTypeData (typeId) {
          this.ItemShow = true
          if (typeId === 1) {
            this.CityCorpItemShow = true
            this.PauroshobaItemShow = false
            this.UnionItemShow = false
          } else if (typeId === 2) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = true
            this.UnionItemShow = false
          } else if (typeId === 3) {
            this.CityCorpItemShow = false
            this.PauroshobaItemShow = false
            this.UnionItemShow = true
          }
        },
        getAreaTypePerData (typeId2) {
          this.ItemShowPer = true
          if (typeId2 === 1) {
            this.CityCorpItemPerShow = true
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = false
          } else if (typeId2 === 2) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = true
            this.UnionItemPerShow = false
          } else if (typeId2 === 3) {
            this.CityCorpItemPerShow = false
            this.PauroshobaItemPerShow = false
            this.UnionItemPerShow = true
          }
        },
        getDistrictList (divisionId = null) {
          const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getDistrictPerList (divisionPerId = null) {
            const districtList = this.$store.state.ExternalUserIrrigation.commonObj.districtList.filter(item => item.status === 0)

            if (divisionPerId) {
                return districtList.filter(district => district.division_id === divisionPerId)
            }

            return districtList
        },
        getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazilaList
        },
        getUpazilaPerList (districtId = null) {
          const upazilaList = this.$store.state.ExternalUserIrrigation.commonObj.upazilaList.filter(item => item.status === 0)

              if (districtId) {
                  return upazilaList.filter(upazila => upazila.district_id === districtId)
              }

              return upazilaList
        },
        getUnionList (upazilaId = null) {
          const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
              if (upazilaId) {
                  return unionList.filter(union => union.upazilla_id === upazilaId)
              }

              return unionList
        },
        getUnionPerList (upazilaId = null) {
          const unionList = this.$store.state.ExternalUserIrrigation.commonObj.unionList.filter(item => item.status === 0)
            if (upazilaId) {
                return unionList.filter(union => union.upazilla_id === upazilaId)
            }

            return unionList
        },
        getCityCorporationList (districtId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getCityCorporationPerList (districtId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.cityCorporationList.filter(item => item.status === 0 && item.district_id === districtId)
            return objectData.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.value, text: obj.text_bn }
                } else {
                    return { value: obj.value, text: obj.text_en }
                }
            })
        },
        getPauroshobaList (upazillaId = null) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getPauroshobaPerList (upazillaId = null) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.pauroshobaList.filter(item => item.upazilla_id === upazillaId)
            if (upazillaId) {
                return objectData.map((obj, key) => {
                    if (this.$i18n.locale === 'bn') {
                        return { value: obj.value, text: obj.text_bn }
                    } else {
                        return { value: obj.value, text: obj.text_en }
                    }
                })
            }
        },
        getWardListByCityCorportaion (cityCorpId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardListByCityCorportaionPer (cityCorpId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.city_corporation_id === cityCorpId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardListByPauroshoba (pauroshobaId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
          const wardObjectList = objectData.filter(item => item.pauroshoba_id === pauroshobaId && item.status === 0)
          return wardObjectList.map((obj, key) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
            } else {
              return { value: obj.value, text: obj.text }
            }
          })
        },
        getWardListByUnion (unionId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                  }
              })
        },
        getWardPerListByUnion (unionPerId) {
          const objectData = this.$store.state.ExternalIncentiveFarmer.commonObjCommonConfig.wardList
            const wardObjectList = objectData.filter(item => item.union_id === unionPerId && item.status === 0)
              return wardObjectList.map((obj, key) => {
                  if (this.$i18n.locale === 'bn') {
                      return { value: obj.value, text: obj.text_bn }
                  } else {
                      return { value: obj.value, text: obj.text }
                 }
             })
        },
        async getApplicationData (id) {
          await RestApi.getData(incentiveGrantServiceBaseUrl, `${applicationShow}/${id}`)
          .then(response => {
            if (response.success) {
              const application = response.data
              application.machine_type_id = 0
              application.instrument_id = 0
              application.brand_id = 0
              application.application_type = 0
              application.model = ''
              application.details = [
                {
                    farmer_name: '',
                    farmer_name_bn: '',
                    father_name: '',
                    father_name_bn: '',
                    mobile_no: '',
                    nid: ''
                }
              ]
              this.application = application
              const farmer = response.data.farmer
              this.getAreaTypeData(this.application.area_type_id)
              if (this.application.is_same === 0) {
                this.same_as_present_address = true
                this.application.per_area_type_id = this.application.area_type_id
                this.application.per_city_corporation_id = this.application.city_corporation_id
                this.application.per_pauroshoba_id = this.application.pauroshoba_id
                this.application.per_division_id = this.application.division_id
                this.application.per_district_id = this.application.district_id
                this.application.per_upazilla_id = this.application.upazilla_id
                this.application.per_union_id = this.application.union_id
                this.application.per_ward_id = this.application.ward_id
                this.application.per_village_id = this.application.village_id
                this.application.per_block_id = this.application.block_id
                this.ItemShowPer = true
                if (this.application.per_area_type_id === 1) {
                    this.CityCorpItemPerShow = true
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 2) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = true
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 3) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = true
                }
              }
              if (response.data.application_type === 2) {
                this.GroupFarmerShow = true
              }
              Object.assign(this.application, farmer)
            }
            this.loading = false
          })
          this.loading = false
        },
        async saveData () {
          this.loading = true
          this.$store.dispatch('mutateCommonProperties', { loading: true })
          let result = null
          const loadingState = { loading: false, listReload: false }
          if (this.$route.query.id) {
            result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${applicationUpdate}/${this.$route.query.id}`, this.application)
          } else {
            const formData = Object.assign(this.application, { mac_farmer_id: this.application.id })
            result = await RestApi.postData(incentiveGrantServiceBaseUrl, applicationStore, formData)
          }
          loadingState.listReload = true
          this.$store.dispatch('mutateCommonProperties', loadingState)
          this.loading = false

          if (result.success) {
              // push notification
            //   const notification = result.notification
            //   this.$socket.emit('send-notification', notification)

              this.$store.commit('mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
              this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
              })
              this.$router.push({ path: '/incentive-farmer/subsidy-application' })
          } else {
            if (result.errors && result.errors.mac_farmer_id) {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: this.$t('externalIncentive.alreadyApplied'),
                    color: '#D6E09B'
                })
              } else {
                this.$toast.error({
                    title: this.$t('globalTrans.error'),
                    message: result.message,
                    color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
          }
          this.$nextTick(() => {
            this.$refs.form.reset()
          })
        },
        saveMessage () {
          this.$swal({
          title: this.$t('externalIncentive.saveMessage'),
          showCancelButton: true,
          confirmButtonText: this.$t('globalTrans.yes'),
          cancelButtonText: this.$t('globalTrans.no'),
          focusConfirm: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.saveData()
            }
          })
        },
        add () {
          const tampbpSetup = {
            farmer_name: '',
            farmer_name_bn: '',
            father_name: '',
            father_name_bn: '',
            mobile_no: '',
            nid: ''
          }
          // this.application.details.push(tampbpSetup)
          const key1 = parseInt(this.application.details.length - 1)
          const item = this.application.details[key1]
          let isEmpty = true
          Object.keys(item).map(key => {
              if (item[key] === '' || item[key] === 0) {
                  isEmpty = false
              }
          })
          if (isEmpty === true) {
            this.application.details.push(tampbpSetup)
          }
        },
        remove (key) {
          this.application.details.splice(key, 1)
        },
        farmerGeneralInfo (appCircularId) {
          this.loading = true
          this.profileStatus = 0
          this.preConditionLoader = true
          RestApi.getData(incentiveGrantServiceBaseUrl, profileInfoShow, { circular_id: appCircularId })
          .then(response => {
            if (response.success) {
              this.circularName = this.$i18n.locale === 'bn' ? response.circular.circular_name_bn : response.circular.circular_name_bn
              this.profileStatus = response.data.save_status === 2 ? 4 : 0
              const application = response.data
              application.circular_id = appCircularId
              application.machine_type_id = 0
              application.instrument_id = 0
              application.brand_id = 0
              application.application_type = 0
              application.model = ''
              application.details = [
                {
                    farmer_name: '',
                    farmer_name_bn: '',
                    father_name: '',
                    father_name_bn: '',
                    mobile_no: '',
                    nid: ''
                }
              ]
              this.application = application
              this.getAreaTypeData(response.data.area_type_id)
              if (response.data.is_same === 1) {
                this.same_as_present_address = true
                this.application.per_area_type_id = this.application.area_type_id
                this.application.per_city_corporation_id = this.application.city_corporation_id
                this.application.per_pauroshoba_id = this.application.pauroshoba_id
                this.application.per_division_id = this.application.division_id
                this.application.per_district_id = this.application.district_id
                this.application.per_upazilla_id = this.application.upazilla_id
                this.application.per_union_id = this.application.union_id
                this.application.per_ward_id = this.application.ward_id
                this.application.per_village_id = this.application.village_id
                this.application.per_block_id = this.application.block_id
                this.ItemShowPer = true
                if (this.application.per_area_type_id === 1) {
                    this.CityCorpItemPerShow = true
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 2) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = true
                    this.UnionItemPerShow = false
                } else if (this.application.per_area_type_id === 3) {
                    this.CityCorpItemPerShow = false
                    this.PauroshobaItemPerShow = false
                    this.UnionItemPerShow = true
                }
              }
            } else {
              this.profileStatus = 0
            }
            this.preConditionLoader = false
            this.loading = false
          })
        },
        async getInstrumentList (circularId) {
          await RestApi.getData(incentiveGrantServiceBaseUrl, `${circInstrList}/${circularId}`)
          .then(response => {
            if (response.success) {
              const datas = response.data.map((obj, key) => {
                if (this.$i18n.locale === 'bn') {
                  return { value: obj.value, text: obj.text_bn, machine_type_id: obj.machine_type_id }
                } else {
                  return { value: obj.value, text: obj.text_en, machine_type_id: obj.machine_type_id }
                }
              })
              this.instrumentListData = datas
                this.instrumentListData.map(item => {
                   const matTypeObj = this.$store.state.ExternalIncentiveFarmer.incentiveFarmerObj.macMachineTypeList.find(obj => obj.value === item.machine_type_id)
                    if (this.machineTypeList.length > 0) {
                        if (!this.machineTypeList.find(obj => obj.value === item.machine_type_id)) { // check if already exist in machineTypeList
                            if (this.$i18n.locale === 'bn') {
                                this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_bn, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                            } else {
                                this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_en, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                            }
                        }
                    } else {
                        if (this.$i18n.locale === 'bn') {
                            this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_bn, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                        } else {
                            this.machineTypeList.push({ value: matTypeObj.value, text: matTypeObj.text_en, text_en: matTypeObj.text_en, text_bn: matTypeObj.text_bn })
                        }
                    }
                })
            }
          })
        },
        displayProfileCheckAlert () {
          if (this.profileStatus !== 0 && this.profileStatus !== 3) {
            return
          }
          this.$swal({
            title: this.profileStatus === 0 ? this.$t('externalPanel.profile_complete_msg') : this.$t('externalPanel.unauthorized_message'),
            showCloseButton: true,
            confirmButtonText: 'Close',
            focusConfirm: false
          })
        }
    }
}
</script>
